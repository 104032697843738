.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.App {
    width: 800px;
    margin: 5rem auto;
    box-shadow: 0 6px 12px 0 rgba(31, 35, 41, 0.08);
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 2rem;
    background: RGBA(250, 250, 250, 1);
}

.App header h2 {
    font-size: 3rem;
    line-height: 1.7;
    margin-top: 0;
}

.BtnBar {
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.875rem 2rem;
    text-align: center;
}

.btn {
    margin: 1rem!important;
}

@media (max-width: 830px) {
    .App {
        width: 95%;
    }

    .App header h2 {
        font-size: 1.5rem;
    }

    .BtnBar {
        bottom: 0;
    }

    .btn {
        margin: .2rem!important;
    }
}
